import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import qs from "qs"

import { AccountContext } from "../../context/account"
import SEO from "../../components/seo"

import MedusaClient from "../../services/api"

const IndexPage = ({ location }) => {
  useEffect(() => {
    const [, ...search] = location.search
    const { state } = qs.parse(search)

    MedusaClient.auth.retrieveCsrfToken(state).then(({ data }) => {
      switch (data.intent) {
        case "cli":
          navigate(`/cli-login/github${location.search}&${qs.stringify(data)}`)
          break
        default:
          navigate(`/login/github${location.search}`)
          break
      }
    })
  }, [])

  return <SEO title="Redirecting..." />
}

export default IndexPage
